// IIFE - Immediately Invoked Function Expression
(function($, window, document) {

    // The $ is now locally scoped 

    // Listen for the jQuery ready event on the document
    $(function() {

        // The DOM is ready!

    });

    /*Dropdown mobile*/
    $('.dropdown-toggle-hover').click(function(){
      event.preventDefault();
      if ($(window).width() < 769) {
        var ddl = $(this).closest('.dropdown');
        ddl.toggleClass('open');
      }
    });

    $(function(){
      // bind change event to select
      $('.dynamic-select').on('change', function () {
          var url = $(this).val(); // get selected value
          if (url) { // require a URL
              window.location = url; // redirect
          }
          return false;
      });
    });    


    //Menu trigger
    var menuTrigger = document.getElementsByClassName("menu-trigger-btn"),
        menu = document.getElementById("main-nav");
    
    var toggleMenu = function() {
    	   console.log('main nav offcanvas called')
        //menu.classList.toggle("offcanvas-nav-active");
        document.body.classList.toggle("menu-active");
    };

    for (var i = 0; i < menuTrigger.length; i++) {
        menuTrigger[i].addEventListener('click', toggleMenu, false);
    }


    //Offcanvas Form trigger
    var searchTrigger = document.getElementsByClassName("search-trigger-btn");
    
    var toggleCanvasForm = function() {
        document.body.classList.toggle("search-active");
    };

    for (var i = 0; i < searchTrigger.length; i++) {
        searchTrigger[i].addEventListener('click', toggleCanvasForm, false);
    }

   
    /*Anchor scroll*/
    const anchors = document.querySelectorAll(".scroll-anchor");
     
    for (const anchor of anchors) {
      anchor.addEventListener("click", clickHandler);
    }
     
    function clickHandler(e) {
      e.preventDefault();
      const header = document.querySelector("header").offsetHeight;
      const href = this.getAttribute("href");
      const offsetTop = document.querySelector(href).offsetTop;
     
      scroll({
        top: offsetTop-header,
        behavior: "smooth"
      });
    }



    document.addEventListener('scroll', function(e) {
      lastKnownScrollPosition = window.scrollY;
      //console.log(lastKnownScrollPosition);
      wHeight = window.innerHeight;
      mainHeader = document.getElementById('main-header');

      if (mainHeader) {
      
        if (lastKnownScrollPosition >= wHeight) {
          mainHeader.classList.add('-fixed');
        }else{
          mainHeader.classList.remove('-fixed');
        }
        
      }

      /*if (!ticking) {
        window.requestAnimationFrame(function() {
          doSomething(lastKnownScrollPosition);
          ticking = false;
        });

        ticking = true;
      }*/
    });



    //Scroller
    const scrRight = document.querySelectorAll("button.scroll-right");
    const scrLeft = document.querySelectorAll(".scroll-left");   
    //console.log(scrRight);

    for (const sctRight of scrRight) {console.log(sctRight);
      sctRight.addEventListener("click", scrRightHandler);
    }

    for (const sctLeft of scrLeft) {
      sctLeft.addEventListener("click", scrLeftHandler);
    }

    
    function scrRightHandler(e) {
      e.preventDefault();
      var sliderWrap = this.closest('.scroll-container').querySelectorAll(".scroll-slider");
      var slider = sliderWrap[0];
      var vw = window.innerWidth;
      var browserScrBar = window.innerWidth - document.body.clientWidth;
      var calcOneSix = (vw/6) + browserScrBar;
      var fivePercent = (vw/100)*5;
      var offsetLeft = slider.scrollLeft;

      var sliderWidth = (slider.childElementCount * calcOneSix)+fivePercent;


      var scrTo = (offsetLeft>0? offsetLeft+calcOneSix: (calcOneSix+fivePercent) );
     
      slider.scroll({
        left: scrTo,
        behavior: "smooth"
      });
    }

    function scrLeftHandler(e) {
      e.preventDefault();
      var sliderWrap = this.closest('.scroll-container').querySelectorAll(".scroll-slider");
      var slider = sliderWrap[0];
      var vw = window.innerWidth;
      var calcOneSix = (vw/6);
      var offsetLeft = slider.scrollLeft;
      var fivePercent = (vw/100)*5;

      var scrTo = ( offsetLeft>0? offsetLeft-calcOneSix : calcOneSix );

      var startPoint = fivePercent+calcOneSix;
      /*console.log(calcOneSix);*/
      /*console.log(scrTo);*/

      scrTo = (scrTo < startPoint? 0: scrTo);
     
      slider.scroll({
        left: scrTo,
        behavior: "smooth"
      });
    }

    /*Tiny slider*/
    const tnsCarousel = document.querySelectorAll('.-as-tns');
    tnsCarousel.forEach(slider => {
          const tnsSlider = tns({
               container: slider,
               items: 3,
               nav: false,
               gutter: 30,
               controlsPosition: "bottom",
               controlsText: ['<span class="iconify" data-icon="akar-icons:chevron-left"></span>', '<span class="iconify" data-icon="akar-icons:chevron-right"></span>'],
               responsive: {
              320: {
                items: 1
              },
              767: {
                items: 2
              },
              900: {
                items: 3
              }
            }
            
          });
    });



    /*GDPR*/
    document.addEventListener('DOMContentLoaded', function() {
      var cookiebar = document.querySelector('.cookiebar');
      if (cookiebar) {
          var closecookiebar = document.querySelector('.hide-cookiebar-target');
          var acceptcookie = document.querySelector('.accept-cookies');
          var refusecookie = document.querySelector('.refuse-cookies');
          var cookie = readCookie('gdpr_cookie_accept');/*Name of cookie*/
          /*console.log(cookie);*/

          if (cookie == "true") {
              cookiebar.style.display = "none";
          }else if (cookie == "false") {
              cookiebar.style.display = "none";
          }else{
              cookiebar.classList.add("no-cookie-yet");
          }

          acceptcookie.addEventListener('click', function(e) {
              if(cookie != "true") {
                cookiebar.style.display = "none";
                createCookie('gdpr_cookie_accept', "true", 7);
              } 
              e.preventDefault();
          });

          refusecookie.addEventListener('click', function(e) {
              if(cookie != "true") {
                cookiebar.style.display = "none";
                createCookie('gdpr_cookie_accept', "false", 7);
              }
              e.preventDefault();
          });

          closecookiebar.addEventListener('click', function(e) {
              cookiebar.style.display = "none";
              /*if(cookie != "true") {
                createCookie('gdpr_cookie_accept', "true", 1);
              } */
              e.preventDefault();
          });

      }

      function createCookie(name, value, days) {
          if(days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            var expires = "; expires=" + date.toGMTString();
          } else var expires = "";
          document.cookie = name + "=" + value + expires + "; path=/"
      }

      function readCookie(name) {
          var nameEQ = name + "=";
          var ca = document.cookie.split(';');
          for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
           
          }
          return null;
      }

      function eraseCookie(name) {
          createCookie(name, "", -1);
      }

    });

    //Function to redirect form after submit
    /*document.addEventListener( 'wpcf7mailsent', function( event ) {
        switch (event.detail.contactFormId ) {
            case '15':
                location = 'http://www./';
                break; 
            default: 
                
        }
    }, false );*/
    //End
    

    /*Input Masks*/
    function runmask() {
      v_obj.value=v_fun(v_obj.value);
    }

    function mask(o,f) {
      v_obj=o;
      v_fun=f;
      setTimeout(runmask(),1)
    }

    function mtel(v) {
      v = v.replace(/\D/g, "");
      v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
      v = v.replace(/(\d)(\d{3})$/, "$1-$2");
      return v;
    }

    function modate(v) {
      v = v.replace(/\D/g, "");
      v = v.replace(/^(\d{2})(\d)/g, "$1/$2");
      v = v.replace(/(\d)(\d{3})$/, "$1/$2");
      return v;
    }

    function mnumbers(v) {
      v = v.replace(/\D/g, "");
      return v;
    }

    function mCpf(v){
      //Remove tudo o que não é dígito
      v=v.replace(/\D/g,"")

      //Coloca um ponto entre o terceiro e o quarto dígitos
      v=v.replace(/(\d{3})(\d)/,"$1.$2")

      //Coloca um ponto entre o terceiro e o quarto dígitos
      //de novo (para o segundo bloco de números)
      v=v.replace(/(\d{3})(\d)/,"$1.$2")

      //Coloca um hífen entre o terceiro e o quarto dígitos
      v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2")

      return v  
    }

    var cpfValidator = document.getElementsByClassName('mask-cpf');
    var telValidator = document.getElementsByClassName('mask-tel');
    var dateValidator = document.getElementsByClassName('mask-date');

    if(cpfValidator && cpfValidator.length != -1){
      for (i = 0; i < cpfValidator.length; i++) {
        cpfValidator[i].addEventListener('keydown', function() {
          mask(this, mCpf);
        });
      }
    }
    if(telValidator && telValidator.length != -1){
      for (i = 0; i < telValidator.length; i++) {
        telValidator[i].addEventListener('keydown', function() {
          mask(this, mtel);
        });
      }
    }
    if(dateValidator && dateValidator.length != -1){
      for (i = 0; i < dateValidator.length; i++) {
        dateValidator[i].addEventListener('keydown', function() {
          mask(this, modate);
        });
      }
    }

    

}(window.jQuery, window, document));